.rw-widget-embedded {

}

.rw-messages-container::-webkit-scrollbar {
    background: #eee;
    width: 4px;
}
.rw-messages-container::-webkit-scrollbar-track {
    background: #eee;
}

.rw-messages-container::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 4px;
}

.rw-messages-container::-webkit-scrollbar-thumb:hover {
    background: #ccc;
    border-radius: 4px;
}

.rw-conversation-container {
  box-shadow: unset !important;
}

.rw-avatar {
  height: 2em !important;
  width: 2em !important;
  margin-top: 18px;
  bottom: -8px !important;
}

.rw-response {
  color: #000000 !important;
  border-radius: 0px !important;
  background-color: #EEF7F9 !important;
}

.rw-client {
  /* color: #ffffff !important; this is added through Widget component userTextColor prop*/
  border-radius: 0px !important;
  /* background-color: #6852E3  !important; this is added through Widget component userBackgroundColor prop */
}

.rw-messages-container {
  height: 100% !important;
  padding: 16px 2px !important;
  background-color: #f6f6f600 !important;
}

.rw-message {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.rw-message-text {
  font-size: 16px !important;
  line-height: 26px !important;
}

.rw-sender,
.rw-new-message,
.rw-send {
  color: #ffffff;
  background-color: #000000 !important;
}

.rw-send svg path {
  fill: #ffffff !important;
}

.rw-group-message.rw-from-response .rw-message:not(:first-child):not(:only-child).rw-with-avatar {
  margin-left: 63px;
}

.rw-with-avatar {
  flex-direction: column !important;
}

.rw-new-message {
  padding-left: 0px !important;
}

.rw-sender {
    /*padding: 10px 1px 10px 18px !important;*/
    padding: 0 !important;
    border-bottom: 0 !important;
    height: 0 !important;;
    min-height: 0 !important;;
    visibility: hidden !important;
}

@media screen and (max-width: 800px) {
  .rw-sender {
    border-radius: 9px !important;
  }
}

.rw-sender-hide {
  visibility: hidden !important;
}

.rw-group-message.rw-from-response .rw-message:not(:first-child):not(:only-child).rw-with-avatar {
  margin-left: 10px !important;
}

.rw-group-message.rw-from-response .rw-message:not(:first-child):not(:only-child) .rw-avatar {
  display: block !important;
}
